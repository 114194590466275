<template>
  <div class="d-flex m-b-ms">
    <div class="flex-1 p-r-sm">
      <div style="background:#3779FD;height: 130px;" class="d-flex flex-column j-center p-w-md">
        <div class="font-white font-md m-b-sm">{{ overview[0].title }}</div>
        <div class="font-white" style="font-size: 52px"> {{ overview[0].number }}<span class="font-white font-md font-bold">单</span> </div>
      </div>
      <div style="height: 130px;margin-top: 15px;" class="d-flex flex-column j-center p-w-md bgItem">
        <div class="font-white font-md m-b-sm">{{ overview[3].title }}</div>
        <div style="font-size: 52px;color: #3779FD;"> {{ overview[3].number }}<span class="font-white font-md font-bold">分</span> </div>
      </div>
    </div>
    <div class="flex-1 p-l-xs p-r-xs">
      <div style="background:#FFA523;height: 130px;" class="d-flex flex-column j-center p-w-md">
        <div class="font-white font-md m-b-sm">{{ overview[1].title }}</div>
        <div class="font-white" style="font-size: 52px"> {{ overview[1].number }}<span class="font-white font-md font-bold">单</span> </div>
      </div>
      <div style="height: 130px;margin-top: 15px;" class="d-flex flex-column j-center p-w-md bgItem">
        <div class="font-white font-md m-b-sm">{{ overview[4].title }}</div>
        <div style="font-size: 52px;color:#FFA523;"> {{ overview[4].number }}<span class="font-white font-md font-bold">分</span> </div>
      </div>
    </div>
    <div class="flex-1 p-l-sm">
      <div style="background:#F52E5A;height: 130px;" class="d-flex flex-column j-center p-w-md">
        <div class="font-white font-md m-b-sm">{{ overview[2].title }}</div>
        <div class="font-white" style="font-size: 52px"> {{ overview[2].number }}<span class="font-white font-md font-bold">单/小时</span> </div>
      </div>
      <div style="height: 130px;margin-top: 15px;" class="d-flex flex-column j-center p-w-md bgItem">
        <div class="font-white font-md m-b-sm">{{ overview[5].title }}</div>
        <div style="font-size: 52px;color:#F52E5A;"> {{ overview[5].number }}<span class="font-white font-md font-bold">个</span> </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'overview',
  props: {
    overview: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  data() {
    return {}
  },
  created() {
    console.log(this.overview, 'todayOrderCount');
  }
}
</script>

<style scoped>

</style>

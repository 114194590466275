<template>
  <div class="w-100 h-100" style="border-top: 2px solid #3675F5;">
    <div class="text-center font-white p-h-sm">分拣机包裹展示</div>
    <div style="height: calc(100% - 40px);">
      <dv-scroll-board :config="packageNumber" class="w-100 h-100" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'packageDisplay',
  props: {
    smallBagRecordList: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  data() {
    return {
      packageNumber:{
        header: ['扫描包裹号'],
        data: [],
        rowNum: 22,
        index: false,
        align: ['left'],
        headerBGC: '#24262D',
        oddRowBGC: '#24262D',
        evenRowBGC: '#1C1D23'
      },
    }
  },
  created() {
    this.packageNumber.data = this.smallBagRecordList;
  }
}
</script>

<style scoped>

</style>


export function formatterCount (number) {
  const numbers = number.toString().split('').reverse()
  const segs = []

  while (numbers.length) segs.push(numbers.splice(0, 3).join(''))

  return segs.join(',').split('').reverse().join('')
}
// 用数组中对象的值倒序排序
export function compare(property){
  return function(a,b){
    const value1 = a[property];
    const value2 = b[property];
    return value2 - value1;
  }
}

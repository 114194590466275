<template>
  <div class="bgItem" style="height: calc(50% - 42px);padding: 10px 15px;">
    <div class="d-flex" style="color:#979797;">
      <div class="flex-1">
        {{ specificDate }}
      </div>
      <div class="d-flex flex-column font-xs">
        <div class="text-right">范围：TOP10</div>
        <div class="text-right">统计单位：个</div>
      </div>
    </div>
    <div style="height: calc(100% - 60px);" class="d-flex w-100">
      <div class="flex-1">
        <dv-active-ring-chart :config="sortingToday" class="h-100 w-100" />
      </div>
      <div class="flex-1 m-t-md">
        <div class="d-flex p-h-sm">
          <div v-for="(item, index) in SortingTodayLeft" :key="index" class="flex-1 font-white font-xs d-inline-block text-left">
            <div v-for="(list, lIndex) in item" :key="lIndex">
              <span
                  class="b-r-50 d-inline-block m-r-xs"
                  style="width: 10px;height: 10px;"
                  :style="{'background':list.color,'border': '1px solid' + list.color}"></span>
              {{ list.name }} {{ list.value }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="text-center font-xs" style="color:#979797;">
      {{ currentTime[0] }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'proportionChannels',
  props: {
    currentTime: {
      type: Array,
      default: () => {
        return {}
      }
    },
    sortingTodayData: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  data() {
    return {
      specificDate: '',
      SortingTodayLeft: [],
      sortingToday: {
        data: [],
        center: ['50%', '50%'],
        radius: '60%',
        animationDelayGap: 10,
        color: ['#F52E5A', '#37FDA3', '#FFA523', '#3779FD', '#BF2EED', '#2EEAED', '#6FED2E', '#ED5D2E', '#2E3BED', '#E8ED2E', '#ED392E', '#08054D', '#0DFC43', '#2E3903', '#2E0331'],
        animationCurve: 'easeOutCubic',
        lineWidth: 10
      },
    }
  },
  created() {
    this.sortingToday.data = this.sortingTodayData;
    this.getSortingTodayLeft();
  },
  methods: {
    // 左侧提示
    getSortingTodayLeft() {
      let i = 0;
      const dataList = [];
      for (let j = 0; j < this.sortingToday.data.length; j++) {
        const list = {
          name: this.sortingToday.data[j].name,
          value: this.sortingToday.data[j].value,
          color: ''
        }
        if (j < this.sortingToday.color.length) {
          i = j
        } else {
          i = (j % this.sortingToday.color.length);
        }
        if (i < this.sortingToday.color.length) {
          list.color = this.sortingToday.color[i];
        }
        dataList.push(list);
      }
      function group(array, subGroupLength) {
        let index = 0;
        let newArray = [];
        while(index < array.length) {
          newArray.push(array.slice(index, index += subGroupLength));
        }
        return newArray;
      }
      this.SortingTodayLeft = group(dataList, 10);
    }
  }
}
</script>

<style scoped>

</style>

<template>
  <div style="background: #1B1C1E;width: 100%;height: 100%;">
<!--    <dv-full-screen-container>-->
      <div class="p-md w-100">
        <div class="d-flex a-center p-w-md bgItem" style="height: 72px;">
          <div class="d-flex flex-1 font-white font-bold" style="font-size: 34px;line-height: 34px;">安骏分拣设备分析平台</div>
          <div class="d-flex flex-column a-center font-white b-r p-r-sm" style="width: 200px;">
            <div class="d-flex j-end font-sm w-100 font-bold">{{ currentTime[0] }}</div>
            <div class="d-flex j-end w-100 font-bold" style="font-size: 22px;">{{ currentTime[1] }}</div>
          </div>
        </div>
        <div class="m-t-sm w-100" style="height: calc(100vh - 122px);">
<!--          分拣机格口-->
          <div class="h-100 w-100">
            <sorting-grid :grid-list="gridList" :currentTime="currentTime" />
          </div>
<!--          分拣机包裹展示-->
          <!--<div class="h-100 p-r-sm" style="width: 200px;">
            <package-display />
          </div>

          <div class="h-100 p-r-sm flex-4">
            <div class="w-100 h-100" style="border-top: 2px solid #ED2D57">
              <div class="font-white p-l-md p-t-sm p-b-sm">概览</div>
              <overview />
              <div class="font-white p-l-md p-t-sm p-b-sm">近30天分拣数量趋势分析</div>
              <trend-analysis />
            </div>
          </div>
          <div class="h-100 flex-2" style="border-top: 2px solid #F7A022;">
            <div class="w-100 h-100">
              <div class="font-white p-l-md p-t-sm p-b-sm">格口落袋数量统计</div>
              <quantity-statistics />
              <div class="font-white p-l-md p-t-sm p-b-sm">今日分拣渠道占比</div>
              <proportion-channels :currentTime="currentTime" />
            </div>
          </div>-->
        </div>
      </div>
<!--    </dv-full-screen-container>-->
  </div>
</template>

<script>
import sortingGrid from '@/views/sorterKanban/comm/sortingGrid';
import { setDateTime3 } from '@/utils/date_util';
import { getGridList } from '@/api/queryData';
export default {
  name: 'index',
  components: {
    sortingGrid
  },
  data() {
    return {
      currentTime: [],
      gridList: []
    }
  },
  created() {
    this.getGridListData();
  },
  methods: {
    // 获取格口列表
    getGridListData() {
      getGridList().then(res => {
        if (res.code === 200) {
          this.gridList = res.data;
        }
      }).catch(err => {
        console.log(err);
      });
    }
  },
  mounted() {
    setInterval(() => {
      const date = new Date();
      this.currentTime = setDateTime3(date).split(' ');
    }, 1000);
  }
}
</script>

<style>
  .packageBg:nth-child(odd){
    background: #24262D;
  }
  .packageBg:nth-child(even){
    background: #1C1D23;
  }
  .bgItem{
    background:#24262D
  }
  .currentLattice{
    background: #3779FD;
  }
  .hoverLattice:hover{
    background: gray;
  }
  .active-ring-info .active-ring-name{
    font-size: 15px!important;
    font-weight: bold;
  }
</style>

<template>
  <div class="bgItem" style="height: calc(100vh - 480px);">
    <div class="text-right font-white font-sm d-flex j-end a-center" style="height: 40px;">
      <span class="b-r-50 d-inline-block m-r-xs" style="background:#BB264B;border: 1px solid #BB264B;width: 15px;height: 15px;"></span>
      包裹数量（个）
    </div>
    <dv-charts :option="sortingTrend" style="height: calc(100% - 40px);" />
  </div>
</template>

<script>
export default {
  name: 'trendAnalysis',
  props: {
    nearly30Days: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {
      sortingTrend: {
        xAxis: {
          data: [],
          axisLine: {
            show: true,
            style: {
              stroke: '#8D9297'
            }
          },
          axisLabel: {
            show: true,
            style: {
              fill: '#8D9297',
              rotate: 20
            }
          }
        },
        yAxis: {
          data: 'value',
          min: 0,
          axisLabel: {
            show: true,
            style: {
              fill: '#8D9297',
            }
          },
          axisLine: {
            show: false
          },
          splitLine: {
            show: true,
            style: {
              stroke: '#8D9297',
            }
          }
        },
        grid: {
          left: 60,
          top: 30,
          right: 20,
          bottom: 40,
          containLabel: true
        },
        series: [
          {
            data: [],
            type: 'line',
            label: {
              show: true,
              style: {
                fontSize: 9
              }
            },
            lineArea: {
              show: true,
              gradient: ['rgba(38, 129, 255, 1)', 'rgba(38, 129, 255, 0)']
            }
          }
        ]
      },
    }
  },
  created() {
    console.log(this.nearly30Days, 'nearly30Days');
    this.sortingTrend.xAxis.data = this.nearly30Days.day;
    this.sortingTrend.series[0].data = this.nearly30Days.count;
  }
}
</script>

<style scoped>

</style>

<template>
  <div class="bgItem" style="height: calc(50% - 40px);padding: 10px 15px;">
    <div class="d-flex font-xs flex-column" style="color:#979797;">
      <div class="text-right">范围：TOP10</div>
      <div class="text-right">统计单位：个</div>
    </div>
    <div style="width: 100%;height: calc(100% - 40px);">
      <dv-charts :option="latticeStatistics" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'quantityStatistics',
  props: {
    baggingStatistics: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {
      latticeStatistics: {
        xAxis: {
          data: [],
          axisLine: {
            show: true,
            style: {
              stroke: '#8D9297'
            }
          },
          axisLabel: {
            show: true,
            style: {
              fill: '#8D9297',
            }
          }
        },
        yAxis: {
          data: 'value',
          min: 0,
          axisLabel: {
            show: true,
            style: {
              fill: '#8D9297',
            }
          },
          axisLine: {
            show: false
          },
          splitLine: {
            show: true,
            style: {
              stroke: '#8D9297',
            }
          }
        },
        grid: {
          left: 40,
          top: 30,
          right: 10,
          bottom: 40,
          containLabel: true
        },
        series: [
          {
            data: [],
            type: 'bar',
            label: {
              show: true,
              style: {
                fill: '#919192',
                fontsize: 9
              }
            },
            backgroundBar: {
              show: true,
              style: {
                fill: 'rgba(42,44,51, 0.6)'
              }
            },
            gradient: {
              color: ['#3779FD']
            }
          }
        ]
      },
    }
  },
  created() {
    this.latticeStatistics.xAxis.data = this.baggingStatistics.latticeSlogan;
    this.latticeStatistics.series[0].data = this.baggingStatistics.count;
  }
}
</script>

<style scoped>

</style>

import serviceNew from '@/utils/request';
// 获取年度订单总量
export const getTotalAnnualOrders = () => {
  return serviceNew.post('/statistics/order/totalAnnualOrders');
};
// 获取年度订单总收入
export const getTotalYearOrderRevenue = () => {
  return serviceNew.post('/statistics/order/totalYearOrderRevenue');
};
// 月度订单变化
export const getMonthOrders = () => {
  return serviceNew.post('/statistics/order/monthOrders');
};
// 渠道总收入
export const getChannelRevenue = () => {
  return serviceNew.post('/statistics/order/channelRevenue');
};
// 客户贡献排行
export const getCustomerContributions = () => {
  return serviceNew.post('/statistics/order/customerContributions');
};
// 地区总收入
export const getTotalRegionalIncome = () => {
  return serviceNew.post('/statistics/order/totalRegionalIncome');
};
// 获取地区列表
export const getLgAreaList = () => {
  return serviceNew.get('/logistics/lgArea/treeList');
};
// 地区列表查询
export const getLgAreaListData = (data) => {
  return serviceNew.post('/logistics/lgArea/list', data);
};
// 获取格口列表
export const getGridList = () => {
  return serviceNew.get('/wms/sorterGrid/list');
};
// 获取落袋记录
export const getSmallBagRecord = () => {
  return serviceNew.get('/wms/sorterStatistical/smallBagRecord');
};
// 今日入库订单数
export const getArriveAtWarehouseCount = () => {
  return serviceNew.get('/wms/sorterStatistical/arriveAtWarehouseCount');
};
// 今日分拣订单数
export const getGridSmallBagCount = () => {
  return serviceNew.get('/wms/sorterStatistical/gridSmallBagCount');
};
// 分拣机看板
export const getSorterKanbanData = () => {
  return serviceNew.get('/statistics/order/sorterStatisticalInfo');
};
// 公司业务统计
export const companyBusinessStatistical = () => {
  return serviceNew.get('/statistics/order/companyBusinessStatisticalInfo');
};




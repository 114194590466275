<template>
  <div class="w-100 h-100" style="">
    <div class="d-flex h-100">
      <div class="h-100 p-r-sm">
        <div class="h-100 bgItem" style="width: 200px;overflow:auto;border-top: 2px solid #3675F5;">
          <div
              :class="currentLattice === 0 ? 'currentLattice' : ''"
              class="p-w-md p-h-sm text-center font-white font-sm cursor-pointer hoverLattice"
              @click="selectLattice({gridNum: 0})">全部格口</div>
          <div
              class="p-w-md p-h-sm text-center font-white font-sm cursor-pointer hoverLattice"
              v-for="(item, index) in gridList"
              :class="item.gridNum === currentLattice ? 'currentLattice' : ''"
              :key="index"
              @click="selectLattice(item)"
          >{{ item.gridNum }}号格口</div>
        </div>
      </div>
      <div class="h-100 p-r-sm" style="width: 200px;">
        <package-display :small-bag-record-list="smallBagRecordList" :key="newKey + 1" />
      </div>
      <div class="h-100 p-r-sm flex-4">
        <div class="w-100 h-100" style="border-top: 2px solid #ED2D57">
          <div class="font-white p-l-md p-t-sm p-b-sm">概览</div>
          <overview :overview="overview" :key="newKey + 2" />
          <div class="font-white p-l-md p-t-sm p-b-sm">近30天分拣数量趋势分析</div>
          <trend-analysis :nearly30Days="nearly30Days" :key="newKey + 3" />
        </div>
      </div>
      <div class="h-100 flex-2" style="border-top: 2px solid #F7A022;">
        <div class="w-100 h-100">
          <div class="font-white p-l-md p-t-sm p-b-sm">格口落袋数量统计</div>
          <quantity-statistics :bagging-statistics="baggingStatistics" :key="newKey + 4" />
          <div class="font-white p-l-md p-t-sm p-b-sm">今日分拣渠道占比</div>
          <proportion-channels :currentTime="currentTime" :sortingTodayData="sortingTodayData" :key="newKey + 5" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import packageDisplay from '@/views/sorterKanban/comm/packageDisplay';
import overview from '@/views/sorterKanban/comm/overview';
import trendAnalysis from '@/views/sorterKanban/comm/trendAnalysis';
import quantityStatistics from '@/views/sorterKanban/comm/quantityStatistics';
import proportionChannels from '@/views/sorterKanban/comm/proportionChannels';
import { compare } from '@/utils/public';
import {
  getSorterKanbanData
} from '@/api/queryData'

export default {
  name: 'latticeMouth',
  components: {
    packageDisplay,
    overview,
    trendAnalysis,
    quantityStatistics,
    proportionChannels
  },
  props: {
    gridList: {
      type: Array,
      default: () => {
        return []
      }
    },
    currentTime: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  data() {
    return {
      currentLattice: 0,
      smallBagRecordList: [],
      smallBagRecordAll: {},
      overview: [
        { title: '今日入库订单数', number: 0 },
        { title: '今日分拣订单数', number: 0 },
        { title: '当前分拣效率', number: 0 },
        { title: '已经分拣时长', number: 0 },
        { title: '预计还需分拣时间', number: 0 },
        { title: '当前使用格口数量', number: 0 }
      ],
      baggingStatistics: {
        latticeSlogan: [],
        count: []
      },
      nearly30Days: {
        day: [],
        count: []
      },
      sortingTodayData: [],
      newKey: new Date().getTime()
    }
  },
  created() {
    this.getSorterKanbanDataItem();
    setInterval(() => {
      this.getSorterKanbanDataItem();
    }, 900000)
  },
  methods: {
    // 格口选择
    selectLattice(item) {
      this.currentLattice = item.gridNum;
      this.smallBagRecordList = [];
      this.overview[1].number = 0;
      let array = [];
      if (this.currentLattice === 0) {
        for (let key in this.smallBagRecordAll) {
          this.smallBagRecordAll[key].map(item => {
            array.push(item.referenceNumber);
          })
        }
      } else {
        for (let key in this.smallBagRecordAll) {
          if (key === this.currentLattice.toString()) {
            this.smallBagRecordAll[key].map(item => {
              array.push(item.referenceNumber);
            })
          }
        }
      }
      array.map(item => {
        this.smallBagRecordList.push([item]);
      });
      this.newKey = new Date().getTime();
    },
    // 获取看板数据
    getSorterKanbanDataItem() {
      getSorterKanbanData().then(res => {
        if (res.code === 200) {
          // 分拣机包裹展示
          this.smallBagRecordAll = res.data.smallBagRecordPerHour;
          this.overview[0].number = res.data.arriveAtWarehouseCountPerDay;
          this.overview[1].number = res.data.smallBagCountPerDay;
          this.overview[2].number = res.data.smallBagCountPerHour;
          this.overview[3].number = res.data.elapsedTimeToday;
          // 计算剩余时间
          const remainingQuantity = res.data.arriveAtWarehouseCountPerDay - res.data.smallBagCountPerDay;
          let efficiency = 0;
          if (res.data.smallBagCountPerHour > 0) {
            efficiency = 60 / res.data.smallBagCountPerHour;
          }

          const remainingTime = (remainingQuantity * efficiency).toFixed(0);
          this.overview[4].number = remainingTime > 0 ? remainingTime : 0;
          this.smallBagRecordList = [];
          let array = [];
          let Lattice = [];
          if (this.currentLattice === 0) {
            for (let key in this.smallBagRecordAll) {
              if (Lattice.indexOf(key) === -1) {
                Lattice.push(key);
              }
              this.smallBagRecordAll[key].map(item => {
                array.push(item.referenceNumber);
              })
            }
          } else {
            for (let key in this.smallBagRecordAll) {
              if (Lattice.indexOf(key) === -1) {
                Lattice.push(key);
              }
              if (key === this.currentLattice.toString()) {
                this.smallBagRecordAll[key].map(item => {
                  array.push(item.referenceNumber);
                })
              }
            }
          }
          array.map(item => {
            this.smallBagRecordList.push([item]);
          });
          this.overview[5].number = Lattice.length;
          // 格口落袋数量统计
          const baggingData = res.data.gridSmallBagCountPerDay;
          const baggingArr = [];
          for (let itemKey in baggingData) {
            const data = {
              keyName: itemKey + '号格口',
              count: parseInt(baggingData[itemKey])
            };
            baggingArr.push(data);
          }
          baggingArr.sort(compare('count'));
          this.baggingStatistics.latticeSlogan = [];
          this.baggingStatistics.count = [];
          baggingArr.map((item, index) => {
            if (index < 10) {
              this.baggingStatistics.latticeSlogan.push(item.keyName);
              this.baggingStatistics.count.push(item.count);
            }
          });
          // 近30天分拣趋势
          this.nearly30Days.day = [];
          this.nearly30Days.count = [];
          for (let i = 0; i < 30; i++) {
            this.nearly30Days.day.unshift(this.getBeforeDate(i + 1));
          }
          const nearly30DaysData = res.data.smallBagCount30Days;
          nearly30DaysData.map(item => {
            this.nearly30Days.count.unshift(parseInt(item));
          });
          const dataArr = JSON.parse(JSON.stringify(this.nearly30Days.count));
          if (dataArr.length < 30) {
            for (let i = 0; i < (30 - dataArr.length); i++) {
              this.nearly30Days.count.unshift(0);
            }
          }
          // 今日分拣渠道占比
          const sortData = res.data.channelPercentageToday.sort(compare('smallBagCount'));
          this.sortingTodayData = sortData.map(item => {
            return {
              name: item.customerChannelName,
              value: item.smallBagCount
            };
          });
          this.newKey = new Date().getTime();
        }
      })
    },
    // 获取近30天日期
    getBeforeDate(n) {
      const d = new Date();
      let year = d.getFullYear();
      let mon = d.getMonth() + 1;
      let day = d.getDate();
      if (day <= n) {
        if (mon > 1) {
          mon = mon - 1;
        } else {
          year = year - 1;
          mon = 12;
        }
      }
      d.setDate(d.getDate() - n);
      year = d.getFullYear();
      mon = d.getMonth() + 1;
      day = d.getDate();
      return year + '-' + (mon < 10 ? ('0' + mon) : mon) + '-' + (day < 10 ? ('0' + day) : day);
    }
  }
}
</script>

<style scoped>

</style>
